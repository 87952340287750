<template>
  <div v-if="isLoading">
    <CCard>
      <CCardBody>
        <h2>Loading... Please wait...</h2>
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="hasLoadingError">
    <CCard>
      <CCardHeader>
        <h2>Error</h2>
      </CCardHeader>
      <CCardBody>
        An Error occurred while trying to load this client.
        <!--
        -->
        Please see your system administrator to resolve the issue.
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="!isDataFetched">
    <CCard>
      <CCardHeader>
        <h2>Client {{ name }} Not Found</h2>
      </CCardHeader>
      <CCardBody
        >This client with code "{{ name }}" could not be loaded.</CCardBody
      >
    </CCard>
  </div>
  <div v-else>
    <CCardGroup deck>
      <CCard>
        <CCardHeader>
          <h2>Quick Help</h2>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="1">
              <MIcon name="fa-2x fa-square-o" />
            </CCol>
            <CCol>
              The user
              <strong>is not</strong> currently assigned to this client.
            </CCol>
          </CRow>
          <CRow>
            <CCol col="1">
              <MIcon name="fa-2x fa-check-square-o" class="text-success" />
            </CCol>
            <CCol>
              The user
              <strong>is</strong> currently assigned to this client.
            </CCol>
          </CRow>
          <CRow>
            <CCol col="1">
              <MIcon name="fa-2x fa-minus-square-o" class="text-danger" />
            </CCol>
            <CCol>
              The user
              <em>was previously</em> assigned to this client, but you have just
              removed them.
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <p style="opacity: 0.7" class="mt-3">
                <MIcon name="fa-info-circle" class="mr-1" />All actions are
                performed immediately - as soon as you assign a user they will
                have access.
              </p>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard v-if="client">
        <CCardHeader>
          <h2>Client User Assignments</h2>
        </CCardHeader>
        <CCardBody>
          <h4>Assign users to {{ client.clientName }}</h4>
          <p class="pt-1">
            Simply check or uncheck any user in the list to assign or remove
            access to the client.
          </p>
          <p class="small hint-text mt-5">
            <strong>Looking for a user you can't see?</strong>
            <br />Administrative level users already have access to all clients.
          </p>
        </CCardBody>
      </CCard>
    </CCardGroup>
    <CCard class="mt-4">
      <CCardHeader>
        <h2>{{ client.clientName }} Assignments</h2>
      </CCardHeader>
      <CCardBody>
        <CDataTable :items="users" :fields="userFields" striped>
          <template #relation="{ item }">
            <td>
              <MIcon
                :name="getAssignedIconName(item)"
                :class="getAssignedClass(item)"
                @click.native="toggleAssigned(item)"
              />
            </td>
          </template>
          <template #name="{ item }">
            <td>{{ item.fullName }} ({{ item.username }})</td>
          </template>
          <template #systemRole="{ item }">
            <td>
              {{ displayRole(item) }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { entityLoadingMixin } from '@/mixins/entity-loading-mixin';

const USER_FIELDS = [
  {
    key: 'relation',
    label: 'Assigned',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'systemRole',
    label: 'System Role',
  },
];

export default {
  name: 'AssignUsers',
  mixins: [entityLoadingMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      showAllTasks: false,
      dispatchPath: 'clients/fetchClientOverview',
      currentItem: null,
      components: [],
    };
  },
  computed: {
    ...mapGetters('users', ['userList', 'loadingUsers', 'systemRoles']),
    client() {
      return this.fetchedData;
    },
    userFields() {
      return USER_FIELDS;
    },
  },
  watch: {
    $route(to, from) {
      // console.log('to: ', to);
      this.$store
        .dispatch('clients/fetchAssignedUsers', to.params)
        .then((u) => {
          this.users = u.map((o) => {
            o.relation = '';
            return o;
          });
        });
    },
  },
  methods: {
    getAssignedIconName(item) {
      switch (item.relation) {
        case 'ASSIGNED':
          return 'fa-2x fa-check-square-o';
        case 'UNASSIGNED':
          if (!item.assigned) return 'fa-2x fa-square-o';
          return 'fa-2x fa-minus-square-o';
        default:
          if (item.assigned) return 'fa-2x fa-check-square-o';
          return 'fa-2x fa-square-o';
      }
    },
    getAssignedClass(item) {
      switch (item.relation) {
        case 'ASSIGNED':
          return 'text-success';
        case 'UNASSIGNED':
          if (!item.assigned) return '';
          return 'text-danger';
        default:
          if (item.assigned) return 'text-success';
          return '';
      }
    },
    toggleAssigned(item, idx) {
      if (item.relation === 'ASSIGNED' || (!item.relation && item.assigned)) {
        this.removeAssignment(item);
      } else {
        this.addAssignment(item);
      }
    },
    removeAssignment(item) {
      let code = this.client.clientCode;
      let user = item.userKey;
      let self = this;
      this.$store
        .dispatch('clients/removeUserAssignment', { code, user })
        .then(() => {
          // console.log('removed user assignment');
          item.relation = 'UNASSIGNED';
        })
        .catch((error) => {
          console.error('Could not remove user assignment');
        });
    },
    addAssignment(item) {
      let code = this.client.clientCode;
      let user = item.userKey;
      let self = this;
      this.$store
        .dispatch('clients/addUserAssignment', { code, user })
        .then(() => {
          // console.log('added user assignment');
          item.relation = 'ASSIGNED';
        })
        .catch((error) => {
          console.error('Could not add user assignment');
        });
    },
    displayRole(item) {
      let role = this.systemRoles.find((r) => r.value === item.systemRole);
      return role ? role.label + ' (' + role.value + ') ' : 'Unknown Role';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // console.log('to: ', to);
      vm.$store.dispatch('clients/fetchAssignedUsers', to.params).then((u) => {
        vm.users = u.map((o) => {
          o.relation = '';
          return o;
        });
      });
    });
  },
};
</script>
